<template>
    <div class="mePage">
        <div class="me_header">
            <div class="box_top">
                <div class="box_hole"></div>
            </div>
            <div class="header_card">
                <div class="student_number">NO.{{userInfo && userInfo.examingNumber}}</div>
                <div class="card_content">
                    <div class="user_avatar">
                        {{sliceUserName(userInfo && userInfo.realName)}}
                    </div>
                    <div class="user_info">
                        <div class="info_item weight">{{userInfo && userInfo.username}}</div>
                        <div class="info_item">姓名：{{userInfo && userInfo.realName}}</div>
                        <div class="info_item">手机：{{userInfo && userInfo.phoneNumber}}</div>
                    </div>
                </div>
            </div>
        </div>
<!--        <div class="userInfo">-->
<!--            <div class="user_avatar">-->
<!--                {{sliceUserName(userInfo && userInfo.realName)}}-->
<!--            </div>-->
<!--            <div class="user_info">-->
<!--                <div class="info_item weight">{{userInfo && userInfo.username}}</div>-->
<!--                <div class="info_item">姓名：{{userInfo && userInfo.realName}}</div>-->
<!--                <div class="info_item">手机：{{userInfo && userInfo.phoneNumber}}</div>-->
<!--                <div class="info_item">学号：{{userInfo && userInfo.examingNumber}}</div>-->
<!--            </div>-->
<!--        </div>-->
        <div class="actionList">
            <div class="action_item"
                 @click="userAction(item.tag)"
                 v-for="(item,index) in actionData" :key="index">
                {{item.title}}
            </div>
        </div>
        <div class="btnArea">
            <van-button round  @click="logOut">注销登录</van-button>
        </div>

        <!--修改密码-->
        <van-action-sheet v-model="editPwdShow" :round="false" title="修改密码">
            <van-form @submit="pwdSubmit">
                <van-field
                        v-model="oldPassword"
                        type="password"
                        label="旧密码"
                        placeholder="旧密码"
                        :rules="[{ required: true, message: '请填写旧密码' }, { validator: this.pwdLength, message: '密码长度必须大于6' }]"
                />
                <van-field
                        v-model="newPassword"
                        type="password"
                        label="新密码"
                        placeholder="新密码"
                        :rules="[{ required: true, message: '请填写新密码' }, { validator: this.pwdLength, message: '密码长度必须大于6' }]"
                />
                <van-field
                        v-model="rePassword"
                        type="password"
                        label="确认密码"
                        placeholder="确认密码"
                        :rules="[{ required: true, message: '请填写确认密码' }, { validator: this.rePwd, message: '两次输入密码不一致' }]"
                />
                <div style="margin: 16px;">
                    <van-button round block type="info" native-type="submit">提交</van-button>
                </div>
            </van-form>
        </van-action-sheet>
        <!--绑定手机号-->
        <van-action-sheet v-model="editPhoneShow" :round="false" title="绑定手机号">
            <van-form @submit="bindPhoneNumber" validate-first>
                <van-field
                        v-model="phoneNumber"
                        label="手机号"
                        placeholder="手机号"
                        :rules="[{ required: true, message: '请填写手机号' }, { validator: this.rePhone, message: '请填写正确的手机号' }]"
                />
                <van-field
                        v-model="code"
                        center
                        label="短信验证码"
                        placeholder="请输入短信验证码"
                        :rules="[{ required: true, message: '请填写短信验证码' }]"
                >
                    <van-button
                            slot="button"
                            size="small"
                            type="primary"
                            :disabled="timerDesc > 0"
                            @click="sendCode"
                    >
                        发送验证码
                        <span v-show="timerDesc > 0">({{ timerDesc }})</span>
                    </van-button>
                </van-field>
                <div style="margin: 16px;">
                    <van-button round block type="info" native-type="submit">提交</van-button>
                </div>
            </van-form>
        </van-action-sheet>
        <van-action-sheet v-model="contactShow" :round="false" title="联系我们">
            <div class="contact_box">
                <div class="contact_info"
                     @click="callPhone(contactInfo && contactInfo.phone)">
                    <van-icon class="contact_item icon" name="phone-o" color="#07c160"/>
                    <div class="contact_item phone">{{contactInfo &&
                        contactInfo.phone}}
                    </div>
                    <div class="contact_item person">{{contactInfo &&
                        contactInfo.person}}
                    </div>
                </div>
                <div class="contact_address">{{contactInfo &&
                    contactInfo.address}}
                </div>
                <div class="contact_btn">
                    <van-button class="contact_btn_item" type="primary" @click="copyContent(contactInfo.phone)"
                                size="normal">复制号码
                    </van-button>
                    <van-button class="contact_btn_item" type="warning" @click="copyContent(contactInfo.address)"
                                size="normal">复制地址
                    </van-button>
                </div>
            </div>
        </van-action-sheet>

    </div>
</template>

<script>
    import UserApi from "../../api/UserApi";
    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {
        name: "Me",
        data() {
            return {
                //操作数据列表
                actionData: [],

                editPwdShow: false,
                oldPassword: '',
                newPassword: '',
                rePassword: '',
                editPhoneShow: false,
                phoneNumber: '',
                code: '',
                
                //计时相关
                timerDesc: 0,
                timerDefault:61,
                timer: null,
                bindWeChat: false,
                weChatInfo: {
                    openid: '',
                    appid: ''
                },
                bindPhoneName: '绑定手机号',
                contactShow: false,//联系方式显示
            }
        },
        computed: {
            ...mapGetters({
                //获取用户信息
                userInfo: 'user/getUserInfo',
                logoInfo: 'common/getLogoInfo',
            }),
            //获取联系方式
            contactInfo() {
                let schoolId = this.$L.myLocal('__SCHOOL_ID__') || this.schoolId;
                let target = this.logoInfo.filter(item => item.schoolId == schoolId);
                // debugger
                if (target[0]) {
                    return target[0].contact;
                }else {
                    return  this.logoInfo[0].contact
                }
            },
        },
        mounted() {
            //校验用户信息
            if(this.$A.isEmptyObj(this.userInfo)){
                this.catchUserInfo();
            }

            if(this.timer) clearInterval(this.timer);
            this.timer = setInterval(()=>{
                if(this.timerDesc > 0) this.timerDesc --;
            },1000)

        },
        activated() {
            // console.log('this.userInfo',this.userInfo);
            this.pageInit();
        },
        methods: {
            ...mapMutations({
                //设置用户信息
                setUserInfo: 'user/setUserInfo'
            }),
            ...mapActions({
                catchUserInfo: 'user/catchUserInfo'
            }),
            // 验证密码长度
            pwdLength(val) {
                return val.length >= 6
            },
            // 验证2次输入的密码是否一致
            rePwd(val) {
                return val == this.newPassword
            },
            // 修改密码
            pwdSubmit() {
                let params = {
                    oldPassword: this.oldPassword,
                    newPassword: this.newPassword,
                    rePassword: this.rePassword
                }
                UserApi.updatePwd(params).then(res => {
                    if (res.success) {
                        this.$toast.success('密码修改成功');
                        this.editPwdShow = false
                    }
                })
            },
            // 验证手机号格式
            rePhone(val) {
                return this.$A.isMobile(val);
                // return /^1[3456789]\d{9}$/.test(val)
            },
            // 获取验证码
            sendCode() {
                if (this.rePhone(this.phoneNumber)) {
                    let params = {
                        phoneNumber: this.phoneNumber
                    }
                    UserApi.sendBindPhoneVerifyCode(params).then(res => {
                        if (res.success) {
                            this.$toast.success('验证码发送成功\n请注意查收');
                            this.timerDesc = this.timerDefault;
                        }
                    })
                }
            },
            // 绑定手机号
            bindPhoneNumber() {
                if (this.rePhone(this.phoneNumber)) {
                    let params = {
                        phoneNumber: this.phoneNumber,
                        code: this.code
                    }
                    UserApi.bindPhoneNumber(params).then(res => {
                        if (res.success) {
                            this.$toast.success('绑定成功');
                        }
                    })
                }
            },
            // 绑定微信
            bindWeChatFun() {
                let params = {
                    openid: this.weChatInfo.openid,
                    appid: this.weChatInfo.appid
                }
                UserApi.bindWeChat(params).then(res => {
                    if (res.success) {
                        this.bindWeChat = false
                        this.$notify({type: 'success', message: '绑定成功'})
                    }
                })
            },
            //拨打点击
            callPhone(str) {
                window.location.href = 'tel://' + str;
            },
            //复制文本
            copyContent(str) {
                const input = document.createElement("input");
                input.setAttribute("readonly", "readonly");
                input.setAttribute("value", str);
                document.body.appendChild(input);
                input.select();
                input.setSelectionRange(0, 9999);
                if (document.execCommand("copy")) {
                    document.execCommand("copy");
                    Notify({type: 'success', message: '复制成功'});
                    // console.log("复制成功");
                }
                document.body.removeChild(input);
            },
            //分割名字
            sliceUserName(name) {
                if (name) {
                    if (name.length > 2) {
                        name = name.substr(0, 2);
                    }
                    return name;
                }
                return '';

            },
            userAction(type) {
                console.log('type', type);
                switch (type) {
                    case 'changePassword':
                        this.editPwdShow = true;
                        break;
                    case 'changePhone':
                        this.editPhoneShow = true;
                        break;
                    case 'contact':
                        this.contactShow = true;
                        break;
                    case 'wrongBook':
                        this.$router.push({path:'/WrongBook'})
                        break;


                }
            },
            pageInit() {
                this.actionData = [
                    {
                        title: '错题本',
                        url: '',
                        tag: 'wrongBook'
                    },
                    {
                        title: '更换手机',
                        url: '',
                        tag: 'changePhone'
                    },
                    {
                        title: '修改密码',
                        url: '',
                        tag: 'changePassword'
                    },
                    {
                        title: '联系我们',
                        url: '',
                        tag: 'contact'
                    },
                ]
            },
            logOut() {
                // this.$toast({message:'123123',duration:0})
                // return false;
                this.$dialog.confirm({
                    className: 'myDialog',
                    title: '登出确认',
                    message: '是否确认注销登录？',
                })
                    .then(() => {
                        UserApi.closeLogin().then(res => {
                            console.log(res);
                            if (res.success) {
                                this.$L.removeLocal('__USER_INFO__', '__SESSION__');
                                this.$toast.success('登出成功');
                                setTimeout(() => {
                                    if (this.$route.path !== '/Login') {
                                        this.$router.replace({path: '/Login'});
                                    }
                                }, 1000)
                            }
                        })
                    })
                    .catch(() => {
                        // on cancel
                    });
            }
        }
    }
</script>

<style lang="scss">
    .mePage {
        @extend .abs_full_screen;
        @extend .flex_column;
        .me_header{
            height: 200px;
            min-height: 200px;
            position: relative;
            perspective:100px;
            /*background: linear-gradient(21deg, #019FE8 0%, #6AD0FF 100%);*/
            background: #019FE8;
            .box_top{
                background: #eee;
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 20px;
                transform:rotateX(60deg);
                transform-origin:100% 100%;
                @extend .flex_row_center;
                .box_hole{
                    height: 5px;
                    width: 90%;
                    background: #999;
                    border-radius: 20px;

                }
            }
            .header_card{
                width:78%;
                height: 140px;
                position: absolute;
                transform: translateX(-50%);
                left: 50%;
                bottom: 11px;
                border: 1px solid #fff;
                border-bottom: 0;
                /*background: linear-gradient(0deg, #019FE8 0%, #6AD0FF 100%);*/
                /*background: linear-gradient(0deg, #0D141F 0%, #1B2738 100%);*/
                background: #DEEEEA;

                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                box-shadow: 11px -11px 10px 0 rgba(0,0,0,0.1);

                @extend .flex_column;
                .student_number{
                    color: #d5b799;
                    text-align: right;
                    padding-right: 12px;
                    font-size: 12px ;
                    font-weight: bold;
                }
                .card_content{
                    margin: 6px 12px;
                    padding-top: 16px;
                    @extend .flex_height_auto;
                    @extend .flex_row_center;
                    align-items: flex-start;
                    .user_avatar {
                        @extend .flex_row_center;
                        margin-right: 24px;
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background: rgba(1, 159, 232, 1);
                        font-size: 18px;
                        font-weight: bold;
                        color: #fff;
                        border: 2px solid #6fbbde;
                    }
                    .user_info {
                        @extend .flex_column;
                        @extend .flex_width_auto;
                        align-items: flex-start;


                        .info_item {
                            color: #333;
                            font-size: 12px;
                            line-height: 25px;

                            &.weight {
                                font-size: 15px;
                                font-weight: bold;
                                color: rgba(51, 51, 51, 1);
                            }
                        }

                    }
                }
            }
        }

        .userInfo {
            @extend .flex_row_center;
            align-items: flex-start;
            justify-content: flex-start;
            box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.1);
            padding: 16px;

            .user_avatar {
                @extend .flex_row_center;
                margin-right: 12px;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: rgba(1, 159, 232, 1);
                font-size: 12px;
                font-weight: bold;
                color: #fff;
            }

            .user_info {
                @extend .flex_column;
                align-items: flex-start;

                .info_item {
                    color: rgba(102, 102, 102, 1);
                    font-size: 12px;
                    line-height: 25px;

                    &.weight {
                        font-size: 15px;
                        font-weight: bold;
                        color: rgba(51, 51, 51, 1);
                    }
                }

            }
        }

        .actionList {
            @extend .flex_height_auto;
            /*padding: 24px 16px;*/
            margin: 24px;
            box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 10%);
            border-radius: 5px;
            padding: 16px;

            .action_item {
                @extend .flex_row_center;
                justify-content: flex-start;
                height: 42px;
                line-height: 42px;
                border-bottom: 1px solid rgba(238, 238, 238, 1);
                font-size: 14px;
                color: rgba(51, 51, 51, 1);
                padding: 0 12px;

                &:active {
                    background: rgba(0, 0, 0, 0.1);
                }
            }
        }

        .btnArea {
            padding: 12px;
            @extend .flex_row_center;

            button {
                flex: 1;
                font-size: 16px;
                color: #fff;
                /*background: rgba(212, 212, 212, 1);*/
                background: linear-gradient(0deg, #019FE8 0%, #6AD0FF 100%);
                &:active{
                    background: linear-gradient(0deg, #019FE8 100%, #6AD0FF 0%);
                    /*background: rgba(255, 101, 92, 1);*/
                }
            }

        }

        .contact_box {
            margin: 12px;

            .contact_info {
                @extend .flex_row_center;
                padding: 12px;

                .contact_item {
                    &:not(:last-child) {
                        margin-right: 12px;
                    }

                    &.icon {
                        font-size: 25px;
                        margin-right: 12px;
                    }

                    &.phone {
                        font-weight: bold;
                        font-size: 16px;

                    }

                    &.person {
                        font-size: 12px;
                        color: #969799;
                    }
                }

            }

            .contact_address {
                font-size: 16px;
                color: #969799;
                width: 100%;
                text-align: center;
            }

            .contact_btn {
                @extend .flex_row_center;
                margin-top: 12px;

                &_item {
                    flex: 1;
                    margin: 12px;
                }
            }
        }


        /*兼容ipad*/
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
            .me_header{
                height: 200px;
                min-height: 200px;
                position: relative;
                perspective:100px;
                /*background: linear-gradient(21deg, #019FE8 0%, #6AD0FF 100%);*/
                background: #019FE8;
                .box_top{
                    background: #eee;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 20px;
                    transform:rotateX(60deg);
                    transform-origin:100% 100%;
                    @extend .flex_row_center;
                    .box_hole{
                        height: 5px;
                        width: 90%;
                        background: #999;
                        border-radius: 20px;

                    }
                }
                .header_card{
                    width:78%;
                    height: 140px;
                    position: absolute;
                    transform: translateX(-50%);
                    left: 50%;
                    bottom: 11px;
                    border: 1px solid #fff;
                    border-bottom: 0;
                    /*background: linear-gradient(0deg, #019FE8 0%, #6AD0FF 100%);*/
                    /*background: linear-gradient(0deg, #0D141F 0%, #1B2738 100%);*/
                    background: #DEEEEA;

                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    box-shadow: 11px -11px 10px 0 rgba(0,0,0,0.1);

                    @extend .flex_column;
                    .student_number{
                        color: #d5b799;
                        text-align: right;
                        padding-right: 24px;
                        font-size: 20px ;
                        font-weight: bold;
                    }
                    .card_content{
                        margin: 6px 12px;
                        padding-top: 16px;
                        .user_avatar {
                            margin-right: 48px;
                            margin-left: 24px;
                            width: 80px;
                            height: 80px;
                            border-radius: 50%;
                            background: rgba(1, 159, 232, 1);
                            font-size: 36px;
                            font-weight: bold;
                            color: #fff;
                            border: 2px solid #6fbbde;
                        }
                        .user_info {
                            @extend .flex_column;
                            @extend .flex_width_auto;
                            align-items: flex-start;


                            .info_item {
                                color: #333;
                                font-size: 20px;
                                line-height: 25px;

                                &.weight {
                                    font-size: 24px;
                                }
                            }

                        }
                    }
                }
            }

            .userInfo {
                @extend .flex_row_center;
                align-items: flex-start;
                justify-content: flex-start;
                box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.1);
                padding: 16px;

                .user_avatar {
                    @extend .flex_row_center;
                    margin-right: 12px;
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    background: rgba(1, 159, 232, 1);
                    font-size: 12px;
                    font-weight: bold;
                    color: #fff;
                }

                .user_info {

                    .info_item {
                        font-size: 24px;
                        line-height: 25px;

                        &.weight {
                            font-size: 15px;
                            font-weight: bold;
                            color: rgba(51, 51, 51, 1);
                        }
                    }

                }
            }

            .actionList {
                margin: 24px;
                padding: 24px;

                .action_item {
                    height: 56px;
                    line-height: 56px;
                    font-size: 20px;
                    padding: 0 24px;

                }
            }

            .btnArea {
                padding: 18px;


                button {
                    height: 56px;
                    font-size: 20px;
                }

            }

            .contact_box {
                margin: 24px;

                .contact_info {
                    padding: 24px;

                    .contact_item {
                        &:not(:last-child) {
                            margin-right: 24px;
                        }

                        &.icon {
                            font-size: 50px;
                            margin-right: 24px;
                        }

                        &.phone {
                            font-size: 32px;

                        }

                        &.person {
                            font-size: 24px;
                        }
                    }

                }

                .contact_address {
                    font-size: 32px;
                }

                .contact_btn {
                    margin-top: 24px;

                    &_item {
                        margin: 24px;
                    }
                }
            }

        }

    }
</style>